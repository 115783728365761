import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact"
export default class extends Controller {
  static targets = [ "feedback", "form" ]

  connect() {
  }

  submit(event) {
    event.preventDefault()

    const list = this.formTarget.getElementsByClassName('freq');

    // toggle red borders around input
    let errors = 0
    for (var i = 0; i < list.length; i++) {
      if (list[i].value.length < 1){
        errors++;
        list[i].classList.add("input-warning");
      }
      else {
        list[i].classList.remove("input-warning");
      }
    }

    // toggle warning
    if ( errors > 0 ) {
      this.feedbackTarget.classList.remove("d-none");
      this.feedbackTarget.textContent = "Vul de verplichte velden in."
    }
    else {
      const vals = this.formTarget.getElementsByClassName('cmsvalidate');
      vals[0].value = 'super' + 'green';

      // replace cached tokens
      const token = document.getElementById("token4d").innerHTML;
      const auths = document.getElementsByName("authenticity_token");

      for (var i = 0; i < auths.length; i++) {
        auths[i].value = token;
      }

      this.formTarget.submit();
    }
  }
}
