// // Import and register all your controllers from the importmap under controllers/*

// import { application } from "controllers/application"

// // Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

import { application } from "./application"

import ContactController from "./contact_controller.js"
application.register("contact", ContactController)

import ScrollerController from "./scroller_controller.js"
application.register("scroller", ScrollerController)
